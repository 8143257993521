:root {
    --evri-brand-1-headers: #007bc4;
    --evri-brand-2-text: #00014d;
}

.driverstep {
    color: rgba(0, 0, 0, 0.185);
    list-style: none;
}

.active {
    color: var(--evri-brand-2-text);
}

.DriverStepList {
    min-height: 522px;
    margin: 0;
    width: 100%;
    background-color: #f6f6f7;
    font-size: 24px;
    font-weight: 800;
    text-align: left;
    padding-left: 20px;
    padding-top: 20px;
    /* height: 700px; */
    position: absolute;
    overflow: hidden;
}
.DriverStepListContainer {
    position: relative;
}

.DriverStepList li {
    margin-top: 40px;
}

.DriverStepList :nth-child(1) {
    margin-top: 20px;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.Lock-status-p {
    font-size: 24px;
    font-weight: 700;
    color: var(--evri-brand-2-text);
    /* animation: fadeIn;
    animation-duration: 0.5s; */
}

.Fade-in-animation {
    animation: fadeIn;
    animation-duration: 1.9s;
}

.ActiveDelivery-container {
    height: 90vh;
    background-color: #e3f3f9;
}
