:root {
    --evri-brand-2-text: #00014d;
}

.No-deliveries-div {
    width: 100%;
    height: 100%;
    color: var(--evri-brand-2-text);
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 24px;
    margin-top: 100px;
}
