:root {
    --evri-brand-1-headers: #007bc4;
    --evri-brand-2-text: #00014d;
    --evri-brand-3-features: #000c8c;
    --evri-brand-4-accents: #53efef;
    --evri-neutral-2-background: #eef2f4;
    --evri-brand-1-links: #006baa;
    --evri-ui-error: #ba0808;
}

.App {
    text-align: center;
    height: 100vh;
}

.App-container {
    height: 100vh;
    width: 100% auto;
    background-color: var(--evri-neutral-2-background);
}

.App-container-background-white {
    background-color: var(--evri-neutral-2-background);
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: var(--evri-neutral-2-background);
}

.App-link {
    color: var(--evri-brand-1-links);
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.Hidden-button {
    opacity: 0;
    border: 10px solid black !important;
}

.Green-button {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    width: 270px;
    height: 100px;
    font-size: 44px;
    color: white;
    border-radius: 4px;
    border: 2px solid white;
    background-color: #3db38d;
}

.Red-button {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    width: 90px;
    height: 35px;
    font-size: 18px;
    color: white;
    border-radius: 4px;
    border: 2px solid white;
    background-color: var(--evri-ui-error);
    /*padding-top: 10px;*/
}

.Dark-blue-button {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    width: 250px;
    height: 50px;
    font-size: 18px;
    background-color: var(--evri-brand-2-text);
    color: white;
    border-radius: 4px;
    border: 2px solid white;
}

.Main-footer {
    position: fixed;
    bottom: 0;
    height: 150px;
    width: 100%;
    background-color: var(--evri-brand-2-text);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.Main-footer-checkLockFlow {
    position: fixed;
    bottom: 0;
    height: 150px;
    width: 100%;
    background-color: var(--evri-brand-2-text);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.Main-footer :nth-child(2) {
    margin-top: 20px;
}
