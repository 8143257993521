:root {
    --evri-brand-1-headers: #007bc4;
}

.Navbar {
    background-color: var(--evri-brand-1-headers) !important;
    margin: 0 auto;
    display: flex;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    /* height: 56px; */
    padding: 15px 20px;
}

.Navbar-brand,
.navbar-brand {
    display: flex;
    align-items: center;
    justify-content: center;
}
.navbar-expand-lg {
    flex-flow: row nowrap !important;
    justify-content: flex-start !important;
}

@media screen and (max-width: 600px) {
    .nav-logo {
        width: 150px;
    }
}
@media screen and (max-width: 374px) {
    .Navbar {
        font-size: 13px;
    }
    .nav-logo {
        width: 140px;
    }
}

.Amplify-signout {
    align-self: end;
    justify-self: end;
}

.nav-link {
    color: rgba(255, 255, 255) !important;
    text-align: left;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.9)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}
