:root {
    --evri-brand-1-headers: #007bc4;
    --evri-brand-2-text: #00014d;
    --evri-neutral-2-background: #eef2f4;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}

.modal-main {
    position: fixed;
    background: white;
    width: 80%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--evri-neutral-2-background);
    min-height: 300px;
    height: 50%;
    max-height: 350px;
    box-shadow: 0px 0px 4px 2px var(--evri-brand-2-text);
}
.Modal-Alert {
    width: 85px;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 20%;
}

.Modal-message {
    font-weight: 800;
    margin: auto;
    font-size: 20px;
    width: 100%;
    text-align: center;
    color: var(--evri-brand-2-text);
    font-family: 'Poppins', sans-serif;
    position: absolute;
    transform: translate(-0%, -50%);
    top: 50%;
}

.Message-modal-button {
    position: absolute;
    transform: translate(-50%, -50%);
    bottom: 0;
}
.display-block {
    display: block;
}

.display-none {
    display: none;
}
