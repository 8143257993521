:root {
    --evri-brand-1-headers: #007bc4;
    --evri-brand-2-text: #00014d;
    --evri-brand-3-features: #000c8c;
    --evri-brand-4-accents: #53efef;
}

.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: var(--evri-brand-4-accents);
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.Checkmark-container {
    padding-top: 100px;
}

.checkmark {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    display: block;
    stroke-width: 4;
    stroke: var(--evri-brand-2-text);
    stroke-miterlimit: 10;
    margin: auto;
    margin-bottom: 47.5px;
    box-shadow: inset 0px 0px 0px #3db38d;
    animation: fill 0.4s ease-in-out 0.4s forwards,
        scale 0.3s ease-in-out 0.9s both;
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}
@keyframes scale {
    0%,
    100% {
        transform: none;
    }
    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}
@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 36px #3db38d;
    }
}
