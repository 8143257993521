.Help-button {
    background: none;
    border: none;
    color: white;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    padding: 0;
}

button:focus {
    outline: none;
}
