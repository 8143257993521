:root {
    --color: #00014d;
}

amplify-authenticator {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 100vh;
}

.button {
    font-family: 'Poppins', sans-serif;
    font-weight: 1000;
    font-size: large !important;
    background-color: #132e4f;
    color: rgb(224, 6, 6) !important;
}
