:root {
    --evri-brand-1-headers: #007bc4;
    --evri-brand-2-text: #00014d;
    --evri-brand-3-features: #000c8c;
    --evri-neutral-2-background: #eef2f4;
}

.Refresh-location {
    height: 63px;
    width: 100%;
    background: var(--evri-neutral-2-background);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #0000008c;
}
.row {
    margin: 0;
}

.Location-updated p {
    font-size: 24px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    color: var(--evri-brand-2-text);
    padding: 0;
    margin: 0;
    text-align: left;
    margin-left: 10px;
}

/* to vertical align small and big font you do display: inline-block; and vertical-align: middle; */
.Updated {
    font-size: 12px;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
}

.Refresh-button {
    text-align: left;
    font-size: 24px;
    margin-left: -10px;
}
