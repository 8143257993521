:root {
    --evri-brand-2-text: #00014d;
}

.Delivery-details {
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    line-height: 1.4;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.308);
    color: var(--evri-brand-2-text);
    background-color: white;
}

.Delivery-details p {
    margin: 0;
    font-size: 12px;
    font-weight: 700;
}

.Customer-details {
    padding-left: 25px;
    width: 100%;
}

.Car-details {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

.Car-image {
    width: 162px;
    margin-bottom: 3px;
    margin-left: 30px;
}

.Background-red {
    background-color: #ba080831;
}
.Background-green {
    background-color: #9ac8724f;
}

.Margin-bottom {
    margin-bottom: 5px;
}
