.scan-container {
    position: absolute;
    width: 100%;
    text-align: center;
    background-color: white !important;
}
.scan-barcode-area {
    height: calc(100vh - 306px) !important;
}
.scan-result {
    font-family: "Montserrat", sans-serif;
    font-size: 21px;
    font-weight: 600;
    color: #132e4f;
    background-color: #e3f3f9 !important;
    height: 90%;
    text-align: center;
}

.scan-result-Error {
    height: 80px;
}

.Light-grey-button {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    width: 250px;
    height: 50px;
    font-size: 18px;
    background-color: #f6f6f7;
    color: #132e4f;
    border-radius: 4px;
    border: 2px solid #132e4f;
}

#scandit-barcode-picker {
    height: 80%;
}

.dbrScanner-video {
    width: 100%;
}