:root {
    --evri-brand-1-headers: #007bc4;
    --evri-brand-2-text: #00014d;
    --evri-brand-3-features: #000c8c;
    --evri-brand-4-accents: #53efef;
    --evri-neutral-2-background: #eef2f4;
    --evri-brand-1-links: #006baa;
    --evri-ui-error: #ba0808;
}

.HelpButtonList {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    height: 100%;
    min-height: 422px;
}

.HelpTextInput {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    height: 80%;
    min-height: 522px;
}

.HelpTextInput label {
    margin-top: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    color: var(--evri-brand-2-text);
}

.buttonListItem {
    display: block;
    width: 40%;
    margin: 5px;
}

.genericButton {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    width: 250px;
    min-height: 50px;
    font-size: 18px;
    background-color: var(--evri-neutral-2-background);
    color: var(--evri-brand-2-text);
    border-radius: 15px;
    border: 2px solid var(--evri-brand-2-text);
}
