.Error {
    background-color: #f6f6f7;
    min-height: 522px;
    margin: 0;
    padding: 0;
    width: 100%;
    font-size: 24px;
    font-weight: 800;
    text-align: left;
    /* height: 600px; */
    height: calc(100vh - 306px) !important;
    /* position: absolute; */
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.Error-message {
    margin-top: 50px;
    font-size: 23px;
    width: 95%;
    text-align: center;
    color: #132e4f;
    font-family: "Montserrat", sans-serif;
}
.Error-footer {
    position: fixed;
    bottom: 0;
    height: 156px;
    width: 100%;
    background-color: #132e4f;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Alert {
    width: 85px;
    margin-top: 63px;
}
