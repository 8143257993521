.Swipe-div {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    width: 260px;
    height: 50px;
    font-size: 18px !important;
}
.styles_rsbcText__16wA0 {
    font-family: "Montserrat", sans-serif !important;
    margin-top: 0px !important;
    color: white !important;
    font-size: 18px !important;
    letter-spacing: normal !important;
    background-color: #132e4f;
    left: 20px !important;
    top: -2px !important;
}
.styles_rsbcSliderArrow__2D9q4 {
    margin-top: -4px !important;
}

.styles_rsbContainer__KXpXK {
    background-color: white !important;
    border: 2px solid white;
}

.styles_rsbcSliderCircle__3G0Zs {
    background-color: white !important;
    height: 50px !important;
    width: 50px !important;
}

.styles_rsbcSliderArrow__2D9q4 {
    border: 2px solid #132e4f !important;
    border-left-color: transparent !important;
    border-bottom-color: transparent !important;
}

.styles_rsbcSlider__1yYxD {
    background-color: white !important;
    top: -2px !important;
}

.styles_rsbcSliderText__3h08j {
    font-family: "Montserrat", sans-serif !important;
    color: #132e4f !important;
    left: -5px !important;
    font-size: 18px !important;
    letter-spacing: normal !important;
}
