:root {
    --evri-brand-2-text: #00014d;
    --evri-brand-3-features: #000c8c;
}

.Modal-button {
    background: none;
    border: none;
    color: white;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    padding: 0;
}
.Sign-out-modal-main {
    position: fixed;
    background: white;
    width: 90%;
    height: auto;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    min-height: 200px;
    height: 50%;
    max-height: 200px;
    box-shadow: 0px 0px 0px 2px var(--evri-brand-3-features);
}

.Sign-out-modal-message {
    font-weight: 800;
    margin: auto;
    font-size: 16.5px;
    width: 100%;
    text-align: center;
    color: var(--evri-brand-2-text);
    font-family: 'Poppins', sans-serif;
    position: absolute;
    transform: translate(-0%, -50%);
    top: 30%;
    padding: 0px 3px;
}

.Sign-out-modal-buttons-div {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    transform: translate(-0%, -50%);
    top: 50%;
    width: 100%;
    gap: 30px;
    /* border: 1px solid black; */
}
