:root {
    --evri-brand-2-text: #00014d;
    --evri-brand-3-features: #000c8c;
}

.Sign-out-confirmation-buttons {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    width: 120px;
    height: 50px;
    font-size: 18px;
    background-color: var(--evri-brand-2-text);
    color: white;
    border-radius: 4px;
    border: 2px solid var(--evri-brand-3-features);
}
