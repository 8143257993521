:root {
    --evri-brand-1-headers: #007bc4;
    --evri-brand-2-text: #00014d;
}

.helpPageModal_incorrectStatus {
    display: flex !important;
    flex-direction: column;
    width: 100%;
}

.modal-footer {
    justify-content: center !important;
}

.btn-primary {
    background-color: var(--evri-brand-2-text) !important;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 700;
    border: none;
}
.btn-danger {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 700;
    border: none;
}

.modal-content {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 700;
}

.modal-body {
    text-align: center !important;
    padding-left: 0.5em;
    padding-right: 0.5em;
}

.modal-content {
    border: none;
}
.modal-header-colour {
    background-color: var(--evri-brand-2-text) !important;
    color: white;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 700;
}

.modal-header button span {
    color: white;
    opacity: 1;
}
.modal-header button.close {
    color: white;
    opacity: 1;
}

.modal-footer-button-container {
    display: flex;
    flex-direction: column;
}

.modal-footer-button-container > * {
    margin: 0.125rem;
}
